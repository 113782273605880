import React, { useMemo } from 'react';
import classNames from 'classnames';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Title from '../../molecules/title/title';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';
import { getCommonOrganismData } from '../../utils/functions';
import Accordion from '../accordion/Accordion';
import './FolderTree.scss';

const FolderTree = ({ data, considerMenuSpace }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  let jsonFile;
  if (data?.json?.value) {
    try {
      jsonFile = JSON.parse(data.json.value);
      //console.log(jsonFile);
    } catch (error) {
      console.error(error);
    }
  }

  const accordions = useMemo(() => {
    const color = data?.accordionColor?.value[0];

    const firstLevelFolders = data?.folder?.firstLevelFolders || [];

    const accordions = firstLevelFolders.map((folder) => {
      if (folder.numberOfDocumentFolders > 0) {
        const secondLevelFolders = folder.secondLevelFolders || [];
        const accordionSectionElements = secondLevelFolders.map(
          (secondLevelFolder) => {
            const documentsFromCDN = jsonFile?.files
              .filter(
                (file) =>
                  file.path === secondLevelFolder.path.replace(/%20/g, ' ')
              )
              .map((file) => {
                return { contentUrl: file.filePath, title: file.fileName };
              });
            let accordionElements = [];

            if (
              documentsFromCDN &&
              secondLevelFolder?.documents?.length === 0
            ) {
              accordionElements = documentsFromCDN.map((documentFromCDN) => {
                const file = {
                  node: {
                    contentUrl: documentFromCDN?.contentUrl,
                    title: documentFromCDN?.title,
                    encodingFormat:
                      'application/' +
                      documentFromCDN?.title.substring(
                        documentFromCDN?.title.lastIndexOf('.') + 1
                      ),
                  },
                };

                return {
                  content: {
                    file,
                    showDate: { value: data?.showDate?.value },
                    showExtension: { value: true },
                  },
                };
              });
            } else {
              const secondLevelDocuments = secondLevelFolder?.documents || [];
              accordionElements = secondLevelDocuments.map(
                (secondLevelDocument) => {
                  const file = {
                    node: {
                      contentUrl: secondLevelDocument?.contentUrl,
                      dateModified: secondLevelDocument?.dateModified,
                      encodingFormat: secondLevelDocument?.encodingFormat,
                      title: secondLevelDocument?.title,
                    },
                  };

                  return {
                    content: {
                      file,
                      showDate: { value: data?.showDate?.value },
                      showExtension: { value: true },
                    },
                  };
                }
              );
            }

            return {
              content: {
                accordionElement: accordionElements,
                sectionTitle: { value: secondLevelFolder.name },
              },
            };
          }
        );

        const accordionSection = accordionSectionElements;

        return {
          value: folder.name,
          content: { accordionBodyColor: { value: [color] }, accordionSection },
          numberOfDocuments: folder.numberOfDocuments,
          numberOfDocumentFolders: folder.numberOfDocumentFolders,
          folderDescription: folder.description,
        };
      } else {
        const documents = folder?.documents || [];

        const accordionElements = documents.map((document) => {
          const file = {
            node: {
              contentUrl: document?.contentUrl,
              dateModified: document?.dateModified,
              encodingFormat: document?.encodingFormat,
              title: document?.title,
            },
          };

          return {
            content: {
              file,
              showDate: { value: data?.showDate?.value },
              showExtension: { value: true },
            },
          };
        });

        const accordionSection = [
          {
            content: {
              accordionElement: accordionElements,
            },
          },
        ];

        return {
          value: folder.name,
          content: { accordionBodyColor: { value: [color] }, accordionSection },
          numberOfDocuments: folder.numberOfDocuments,
          numberOfDocumentFolders: folder.numberOfDocumentFolders,
          folderDescription: folder.description,
        };
      }
    });

    return accordions;
  }, [data, jsonFile]);

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='ex-partner'
      data-module-code='C-85'
      className={classNames(`folder-tree wrapper ${marginTop}`, {
        'folder-tree--colored-background': backgroundColor !== 'transparent',
      })}
      style={{
        backgroundColor: `var(--${backgroundColor})`,
      }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      {accordions?.map((accordion, key) => (
        <Accordion key={key} data={accordion} />
      ))}
    </Wrapper>
  );
};

export default withPreview(FolderTree);
